<template>
    <div class="container">
        <headerBar :title="type === '1'? '月卡免费课签到规则': '限定免费团课签到规则'" left-arrow @click-left="appBack"/>
        <div class="content">
            
            
            <div class="popup-box-sign col-start-center">
                <div>
                    <p class="fw6">扫码签到：</p>
                    <p class="mt16">用户须在课程开始前30分钟至课后60分钟内进行扫码签到，未在规定时间内完成签到视为旷课。若用户出馆前都未进行签到，则不享受团课的免费时长权益，即在用户出馆时扣除相应的自助时长权益。</p>
                    <p class="fw6 mt64">旷课受限：</p>
                    <p class="mt16">卡生效期间，若用户在自然周内旷课2节及以上，则下一个自然周内只能预约1节月卡免费课，权益在该受限自然周结束后恢复；若在权益受限期间再次旷课，下一个自然周仍旧只有1节月卡免费课的预约权限。</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import userMixin from '@/mixin/userMixin'
import {newAppBack, initBack, gotoAppPage, goMyVoucher} from '@/lib/appMethod'
import {getFormatDate, getParam, initPage} from '@/lib/utils'
import headerBar from '@/components/app/headBar'

export default {
    mixins: [userMixin],
    components:{headerBar},
    data() {
        return {
            type: ''
        }
    },
    async created() {
        initBack()
        this.type = this.$route.query.type || ''
        if(this.type === '1'){
            this.$nextTick(() => {
                document.title = '月卡免费课签到规则'
            })
        }
    },
   
    methods: {
        appBack: newAppBack,
    },
}
</script>
<style lang="less" scoped>
.popup-box-sign{
    width: 750px;
    background-color: white;
    h4{
        font-size: 32px;
        padding:40px 32px 40px;
        text-align: center;
    }
    div{
        padding: 32px;
        font-size: 26px;
        color: #242831;
        p{
            line-height: 48px;
        }
    }
}
</style>
<style>
.van-popover__action {
    padding: 0 !important;
    width: 228px;
}
</style>
